import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import { UrlPaths } from "../../utilities/types";
import usePostNewTrust from "../../hooks/trustHooks/usePostNewTrust";
import { TTrustsData } from "../../utilities/trustTypes";
import TrustForm from "./TrustForm";

export default function NewTrust() {
  const { submitTrust, isLoading, error } = usePostNewTrust();

  if (error != null) {
    console.error("Error in NewTrust is: ", error);
  }

  const sendNewTrustData = (trustsData: TTrustsData) => {
    submitTrust(trustsData);
  };
  return (
    <>
      <Container fluid>
        <Row className="m-3 justify-content-center">
          <Row className="justify-content-center">
            <Col className="col-auto">
              <h3>Create New Trust</h3>
            </Col>
          </Row>
          <TrustForm
            trustsData={null} // not editing existing data so pass null
            isLoading={isLoading}
            error={error}
            apiFunction={sendNewTrustData}
            formPurpose="create"
          />
        </Row>
        <Button style={{ marginLeft: "1rem" }} href={UrlPaths.Trusts}>
          Back
        </Button>
      </Container>
      {error != null ? <div>The error is: {error.message}</div> : null}
    </>
  );
}
