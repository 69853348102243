import { Button, Form, Row, Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  TDirectivesData,
  TSuccessorData,
  TTrusteeData,
  TTrustsData,
} from "../../utilities/trustTypes";
import { defaultTrusteeData } from "./form_sub_components/CPT/CPTTrustees";
import { defaultSuccessorData } from "./form_sub_components/Successors";
import {
  getDefaultDecedentData,
  getDefaultFeesData,
  getDefaultRealEstateData,
  getDefaultSuccessorData,
  getDefaultDirectivesData,
  getDefaultTrustData,
  getDefaultTrusteeData,
} from "../../utilities/defaultDataTypes";
import {
  SuccessorTypes,
  TrusteeTypes,
  TrustTypes,
} from "../../utilities/types";
import { TrustFormTabs } from "../page-level/TrustFormTabs";
import { defaultDirectivesData } from "./form_sub_components/Directives";

const TrustFormStyles = styled.div`
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;

  h4 {
    padding: 14px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: #4c5637;
  }
`;

const StyledFormLabel = styled(Form.Label)`
  font-weight: 600;
  font-size: 20px;
  margin-top: 18px;
`;

const StyledSubmit = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
`;

export const defaultTrustsData: TTrustsData = {
  trust: getDefaultTrustData(),
  trustees: [getDefaultTrusteeData()],
  decedent: getDefaultDecedentData(),
  successors: [getDefaultSuccessorData()],
  directives: [getDefaultDirectivesData()],
  realEstate: getDefaultRealEstateData(),
  fees: getDefaultFeesData(),
};

type TTrustFormPurpose = "create" | "read" | "update";

export default function TrustsForm({
  trustsData,
  isLoading,
  error,
  apiFunction,
  readOnly,
  formPurpose,
}: {
  trustsData: TTrustsData | null;
  isLoading: boolean;
  error: any;
  apiFunction?: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
  formPurpose: TTrustFormPurpose;
}) {
  const [formData, setFormData] = useState<TTrustsData>(defaultTrustsData);
  const [isSuccessorAddMoreHidden, setIsSuccessorAddMoreHidden] =
    useState(false);
  const [isTrusteeAddMoreHidden, setIsTrusteeAddMoreHidden] = useState(false);
  const [trustSelection, setTrustSelection] = useState<TrustTypes | null>(null);

  useEffect(() => {
    if (trustsData != null) {
      setFormData(trustsData);
      setTrustSelection(trustsData.trust.type as TrustTypes);
    }
  }, [setFormData, trustsData]);

  useEffect(() => {
    setIsSuccessorAddMoreHidden(formData.successors?.length >= 3);
  }, [formData.successors]);

  useEffect(() => {
    const trustType = formData.trust.type;
    setIsTrusteeAddMoreHidden(
      formData.trustees && trustType === "CPT" && formData.trustees.length >= 2
    );
  }, [formData.trust.type, formData.trustees]);

  const handleTrustSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setTrustSelection(value as TrustTypes);

    setFormData((prevData) => ({
      ...prevData,
      trust: {
        ...prevData.trust,
        type: value as TrustTypes,
      },
    }));
  };

  const removeIdProperty = <T extends { id?: number }>(
    obj: T | null | undefined
  ): Omit<T, "id"> => {
    if (!obj) {
      return {} as Omit<T, "id">;
    }
    const { id, ...rest } = obj;
    return rest as Omit<T, "id">;
  };

  const updateTrusteeType = (trustees: TTrusteeData[]) => {
    return trustees.map((trustee, i) => {
      if (formData.trust.type === "SPT") {
        trustee.type = TrusteeTypes.SPT;
      } else {
        trustee.type = i === 0 ? TrusteeTypes.CPT1 : TrusteeTypes.CPT2;
      }
      return {
        ...removeIdProperty(trustee),
      };
    });
  };

  const updateSuccessorType = (successors: TSuccessorData[]) => {
    return successors.map((successors, i) => {
      if (i === 0) {
        successors.type = SuccessorTypes.Successor1;
      } else if (i === 1) {
        successors.type = SuccessorTypes.Successor2;
      } else {
        successors.type = SuccessorTypes.Successor3;
      }
      return {
        ...removeIdProperty(successors),
      };
    });
  };

  const updateDirectives = (
    directives: TDirectivesData[]
  ): TDirectivesData[] => {
    return directives.map((directive) => {
      return {
        ...removeIdProperty(directive),
      };
    });
  };

  const updatedPostData = (formData: TTrustsData) => {
    return {
      trust: removeIdProperty(formData.trust),
      trustees: updateTrusteeType(formData.trustees),
      decedent:
        formData.trust.type !== "TA"
          ? null
          : removeIdProperty(formData.decedent),
      successors: updateSuccessorType(formData.successors),
      directives: updateDirectives(formData.directives),
      realEstate: removeIdProperty(formData.realEstate),
      fees: removeIdProperty(formData.fees),
    };
  };

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedFormData = updatedPostData(formData);

    if (apiFunction) {
      apiFunction(updatedFormData);
    }
  };

  const addTrustee = () => {
    setFormData({
      ...formData,
      trustees:
        formData.trustees == null
          ? [defaultTrusteeData]
          : [...formData.trustees, defaultTrusteeData],
    });
  };
  const addSuccessors = () => {
    const newSuccessors =
      formData.successors == null
        ? [defaultSuccessorData]
        : [...formData.successors, defaultSuccessorData];

    setFormData({
      ...formData,
      successors: newSuccessors,
    });

    if (formData.successors.length === 3) {
      setIsSuccessorAddMoreHidden(true);
    }
  };

  const addDirectives = () => {
    const newDirectives = formData.directives?.length
      ? [...formData.directives, defaultDirectivesData]
      : [defaultDirectivesData];

    setFormData({
      ...formData,
      directives: newDirectives,
    });
  };

  // const addMoreAKA = (key: keyof TTrustsData, index: number) => {
  //   const updatedData = { ...formData };

  //   const targetArray = updatedData[key] as Array<any>;

  //   if (targetArray && targetArray[index]) {
  //     const akaNames = targetArray[index].akaNames || [];
  //     targetArray[index].akaNames = [...akaNames, defaultAkaData];

  //     setFormData(updatedData);
  //   }
  // };

  if (isLoading && (formPurpose === "read" || formPurpose === "update")) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner as="span" animation="border" />
      </div>
    );
  } else {
    return (
      <TrustFormStyles>
        {!trustSelection && (
          <div className="mb-4">
            <StyledFormLabel>Select Trust Type:</StyledFormLabel>
            <div>
              <Form.Check
                inline
                type="radio"
                id="trustTypeCPT"
                name="trustType"
                label="CPT"
                value={TrustTypes.CPT}
                onChange={(e) => handleTrustSelection(e, e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                id="trustTypeSPT"
                name="trustType"
                label="SPT"
                value={TrustTypes.SPT}
                onChange={(e) => handleTrustSelection(e, e.target.value)}
              />
              <Form.Check
                inline
                type="radio"
                id="trustTypeTA"
                name="trustType"
                label="TA"
                value={TrustTypes.TA}
                onChange={(e) => handleTrustSelection(e, e.target.value)}
              />
            </div>
          </div>
        )}
        {trustSelection && (
          <Row>
            <Form onSubmit={onFormSubmit}>
              <TrustFormTabs
                formData={formData}
                setFormData={setFormData}
                readOnly={readOnly}
                apiFunction={apiFunction}
                addTrustee={addTrustee}
                addSuccessors={addSuccessors}
                addDirectives={addDirectives}
                isSuccessorAddMoreHidden={isSuccessorAddMoreHidden}
                isTrusteeAddMoreHidden={isTrusteeAddMoreHidden}
              />
              <StyledSubmit>
                {apiFunction != null ? (
                  <Button className="w-10" type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <Spinner as="span" animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                ) : null}
              </StyledSubmit>
            </Form>
          </Row>
        )}
        {error != null ? <div>The error is: {error.message}</div> : null}
      </TrustFormStyles>
    );
  }
}
