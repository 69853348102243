import React, { createContext, useContext, useEffect, useState } from 'react';
import {JwtPayload, jwtDecode} from 'jwt-decode';
import { Role } from "../../utilities/types";


type AuthUser = {
  username: string,
  role: Role,
  fullName: string,
  initials: string,
}

type AuthContextType = {
  token: string | null,
  user: AuthUser | null,
  expiration: string | null;
  handleSetAuthData: (jsonResponse: any) => void,
  handleClearAuthData: () => void,
}

type AuthProviderProps = {
  children: React.ReactNode;
}

type CustomJWTPayload = {
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": Array<string> | null;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name": string;
} & JwtPayload;

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

  const [token, setToken] = useState<string | null>(() => {
    return localStorage.getItem('token');
  });

  const [expiration, setExpiration] = useState<string | null>(() => {
    return localStorage.getItem('expiration');
  });

  const [user, setUser] = useState<AuthUser | null>(() => {
    const storedToken = localStorage.getItem('authToken');
      if (storedToken) {
        try {
          return jwtDecode<AuthUser>(storedToken);
        } catch {
          return null;
        }
      }
    return null;
  });
    
  const handleSetAuthData = (jsonResponse: any) => {
    setToken(jsonResponse.token);
    setExpiration(jsonResponse.expiration)

    const decodedToken =
      jsonResponse != null
        ? (jwtDecode(jsonResponse?.token) as CustomJWTPayload)
        : null;

    if (decodedToken != null) {
      const roles =
        decodedToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
        ];
      const token = jsonResponse.token;
      const expirationTime = decodedToken.exp ?? 0;
      const currentUser = jsonResponse.fullName;
      const userInitials = jsonResponse.initials;

      let role = Role.USER;
      if (roles?.includes(Role.ADMIN.toString())) {
        role = Role.ADMIN;
      } else if (roles?.includes(Role.EMPLOYEE.toString())) {
        role = Role.EMPLOYEE;
      }
      
      setToken(jsonResponse.token);
      setUser({
        role,
        username: jsonResponse.username,
        fullName: jsonResponse.fullName,
        initials: jsonResponse.initials
      });
      setExpiration(String(decodedToken.exp))

      localStorage.setItem("role", role);
      localStorage.setItem("token", token.toString());
      localStorage.setItem("expiration", String(expirationTime));
      localStorage.setItem("currentUser", currentUser);
      localStorage.setItem("currentUserInitials", userInitials);

    };
  }

  const handleClearAuthData = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.removeItem('expiration');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserInitials');
  };

  useEffect(() => {
    if (token) {
      const expirationTime = localStorage.getItem('expiration');
      if (expirationTime) {
        const expiration = parseInt(expirationTime) * 1000; // Convert to milliseconds
        if (Date.now() >= expiration) {
          handleClearAuthData();
        }
      }
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ token, user, expiration, handleSetAuthData, handleClearAuthData }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};