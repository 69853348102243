import { Container, Row } from "react-bootstrap";
import { useParams } from "wouter";
import { useEffect } from "react";
import { HTTPMethods } from "../../utilities/types";
import useOneTrustData from "../../hooks/trustHooks/useOneTrustData";
import TrustInfoTab from "./TrustInfoTab";

export default function ViewTrust() {
  const params = useParams();
  const { oneTrustData, trustsData, isLoading, error } = useOneTrustData();

  const trustID = params.id ?? "";

  useEffect(() => {
    oneTrustData(trustID, HTTPMethods.GET);
  }, [oneTrustData, trustID]);

  return (
    <Container fluid>
      <Row>
        <TrustInfoTab trustID={trustID} trustsData={trustsData} isLoading={isLoading} error={error} />
      </Row>
    </Container>
  );
}
