import { useState, useCallback } from 'react';
import { useLocation } from 'wouter';
import { jwtDecode } from 'jwt-decode';

import { UrlPaths } from "../../utilities/types";
import { useAuth } from './auth-context';

interface LoginCredentials {
  userName: string;
  password: string;
}

interface AuthError {
  message: string;
  code?: string;
}

interface UseAuthenticationReturn {
  sendLogin: (credentials: LoginCredentials) => Promise<void>;
  isLoading: boolean;
  error: AuthError | null;
}

export const useLogin = (): UseAuthenticationReturn => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<AuthError | null>(null);
  const [, setLocation] = useLocation();
  const { handleSetAuthData } = useAuth();

  const sendLogin = useCallback(async (credentials: LoginCredentials) => {
    const url =
      "https://hometrustapi.azurewebsites.net/api/Authenticate/login";
      
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Login failed');
      }

      const jsonResponse = await response.json();
      
      if (
        !jsonResponse.hasOwnProperty("token") ||
        !jsonResponse.hasOwnProperty("expiration")
      ) {
        throw new Error(
          "Json response from login is missing token or expiration field"
        );
      }

      try {
        jwtDecode(jsonResponse.token);
      } catch (e) {
        throw new Error('Invalid token received from server');
      }

      await handleSetAuthData(jsonResponse)
      
      setLocation(UrlPaths.Trusts);
    } catch (err) {
      setError({
        message: err instanceof Error ? err.message : 'An unexpected error occurred',
        code: 'AUTH_ERROR'
      });
      throw err;
    } finally {
      setIsLoading(false);
    }
  }, [handleSetAuthData, setLocation]);

  return {
    sendLogin,
    isLoading,
    error
  };
};