import { Col, Form, Row } from "react-bootstrap";
import { TTrustData, TTrustsData } from "../../../utilities/trustTypes";
import { getDefaultTrustData } from "../../../utilities/defaultDataTypes";
import styled from "styled-components";
import MaskedInput from "react-text-mask";
import { useEffect, useState } from "react";

const StyledSecondaryH5 = styled.h5`
  color: #656e51;
  font-weight: bold;
`;

const StyledFormLabel = styled(Form.Label)`
  font-weight: 600;
  color: #4c5637;
`;

export const defaultTrustData: TTrustData = getDefaultTrustData();

export default function SigningDetails({
  formData,
  setFormData,
  readOnly,
}: {
  formData: TTrustsData;
  setFormData: (trustsData: TTrustsData) => void;
  readOnly?: boolean;
}) {
  const updateCurrentSigningDetails = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key: keyof TTrustData
  ) => {
    if (readOnly) return;
    let value: string | boolean = event.currentTarget.value;

    if (value === "true") {
      value = true;
    } else if (value === "false") {
      value = false;
    }

    const updatedSigningDetails = {
      ...formData.trust,
      [key]: value,
    };

    setFormData({
      ...formData,
      trust: updatedSigningDetails,
    });
  };

  const [otherSelected, setOtherSelected] = useState(false);
  const [otherText, setOtherText] = useState("");

  const [carolWitness, setCarolWitness] = useState(
    formData.trust?.witness1Name === "Carol Mariani" ||
      formData.trust?.witness2Name === "Carol Mariani"
  );
  const [marcoWitness, setMarcoWitness] = useState(
    formData.trust?.witness1Name === "Marco Mariani" ||
      formData.trust?.witness2Name === "Marco Mariani"
  );
  const [michaelaWitness, setMichaelaWitness] = useState(
    formData.trust?.witness1Name === "Michaela Mariani" ||
      formData.trust?.witness2Name === "Michaela Mariani"
  );

  const { witness1Name, witness2Name } = formData.trust;

  const handleCheckboxChange = (checkboxValue: string, isChecked: boolean) => {
    const { witness1Name, witness2Name } = formData.trust;
    let updatedWitness1 = witness1Name;
    let updatedWitness2 = witness2Name;

    if (isChecked) {
      if (!witness1Name) {
        updatedWitness1 = checkboxValue;
      } else if (!witness2Name) {
        updatedWitness2 = checkboxValue;
      }
    } else {
      if (witness1Name === checkboxValue) {
        updatedWitness1 = "";
      } else if (witness2Name === checkboxValue) {
        updatedWitness2 = "";
      }
    }

    setFormData({
      ...formData,
      trust: {
        ...formData.trust,
        witness1Name: updatedWitness1,
        witness2Name: updatedWitness2,
      },
    });

    setCarolWitness(
      updatedWitness1 === "Carol Mariani" || updatedWitness2 === "Carol Mariani"
    );
    setMarcoWitness(
      updatedWitness1 === "Marco Mariani" || updatedWitness2 === "Marco Mariani"
    );
    setMichaelaWitness(
      updatedWitness1 === "Michaela Mariani" ||
        updatedWitness2 === "Michaela Mariani"
    );
  };

  const handleOtherCheckboxChange = (isChecked: boolean) => {
    setOtherSelected(isChecked);

    if (isChecked) {
      const updatedTrust = {
        ...formData.trust,
        witness2Name: formData.trust.witness1Name
          ? otherText
          : formData.trust.witness2Name,
        witness2Address: "",
        witness2Number: "",
      };

      setFormData({
        ...formData,
        trust: updatedTrust,
      });
    } else {
      setFormData({
        ...formData,
        trust: {
          ...formData.trust,
          witness2Name: "",
          witness2Address: "",
          witness2Number: "",
        },
      });
      setOtherText("");
    }
  };

  useEffect(() => {
    if (
      witness2Name &&
      !["Carol Mariani", "Marco Mariani", "Michaela Mariani"].includes(
        witness2Name
      )
    ) {
      setOtherSelected(true);
      setOtherText(witness2Name);
    } else {
      setOtherSelected(false);
      setOtherText("");
    }
  }, [witness1Name, witness2Name]);

  useEffect(() => {
    if (otherText === "") {
      if (
        (witness1Name &&
          !["Carol Mariani", "Marco Mariani", "Michaela Mariani"].includes(
            witness1Name
          )) ||
        (witness2Name &&
          !["Carol Mariani", "Marco Mariani", "Michaela Mariani"].includes(
            witness2Name
          ))
      ) {
        setOtherSelected(true);
        setOtherText(witness2Name || "");
      }
    }
  }, [witness1Name, witness2Name, otherSelected, otherText]);

  const selectedCount = [
    formData.trust.witness1Name,
    formData.trust.witness2Name || otherSelected === true,
  ].filter(Boolean).length;

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="signingDate">
            <Form.Label>Date of Signing Docs</Form.Label>
            <Form.Control
              name="signingDate"
              type="date"
              value={formData.trust?.signingDate}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "signingDate")
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="signingCity">
            <Form.Label>City of Signing Docs</Form.Label>
            <Form.Control
              name="signingCity"
              type="text"
              value={formData.trust?.signingCity}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "signingCity")
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="signingCounty">
            <Form.Label>County of Signing Docs</Form.Label>
            <Form.Control
              name="signingCounty"
              type="text"
              value={formData.trust?.signingCounty}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "signingCounty")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <StyledSecondaryH5>Witnesses</StyledSecondaryH5>
      </Row>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Select Witnesses</Form.Label>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                name="witnesses"
                type="checkbox"
                label="Carol Mariani"
                value="Carol Mariani"
                onChange={(e) =>
                  handleCheckboxChange("Carol Mariani", e.target.checked)
                }
                checked={carolWitness}
                disabled={selectedCount >= 2 && !carolWitness}
              />
              <Form.Check
                inline
                name="witnesses"
                type="checkbox"
                label="Marco Mariani"
                value="Marco Mariani"
                onChange={(e) =>
                  handleCheckboxChange("Marco Mariani", e.target.checked)
                }
                checked={marcoWitness}
                disabled={selectedCount >= 2 && !marcoWitness}
              />
              <Form.Check
                inline
                name="witnesses"
                type="checkbox"
                label="Michaela Mariani"
                value="Michaela Mariani"
                onChange={(e) =>
                  handleCheckboxChange("Michaela Mariani", e.target.checked)
                }
                checked={michaelaWitness}
                disabled={selectedCount >= 2 && !michaelaWitness}
              />
              <Form.Check
                inline
                name="witnesses"
                type="checkbox"
                label="Other"
                checked={otherSelected}
                onChange={(e) => handleOtherCheckboxChange(e.target.checked)}
                disabled={selectedCount >= 2 && !otherSelected}
              />
            </Form.Group>
            {otherSelected && (
              <>
                <Row>
                  <Col xs={5}>
                    <Form.Group controlId="otherWitness">
                      <StyledFormLabel>Name of Other Witness</StyledFormLabel>
                      <Form.Control
                        type="text"
                        value={formData.trust?.witness2Name}
                        onChange={(target) =>
                          updateCurrentSigningDetails(target, "witness2Name")
                        }
                        disabled={!otherSelected}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={7}>
                    <Form.Group className="mb-3" controlId="witness2Address">
                      <StyledFormLabel>Other Witness Address</StyledFormLabel>
                      <Form.Control
                        type="text"
                        value={formData.trust?.witness2Address}
                        onChange={(target) =>
                          updateCurrentSigningDetails(target, "witness2Address")
                        }
                        disabled={!otherSelected}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={4}>
                    <Form.Group className="mb-3" controlId="witness2Number">
                      <StyledFormLabel>
                        Other Witness Phone Number
                      </StyledFormLabel>
                      <MaskedInput
                        className="form-control"
                        mask={[
                          "(",
                          /[1-9]/,
                          /\d/,
                          /\d/,
                          ")",
                          " ",
                          /\d/,
                          /\d/,
                          /\d/,
                          "-",
                          /\d/,
                          /\d/,
                          /\d/,
                          /\d/,
                        ]}
                        value={formData.trust?.witness2Number}
                        onChange={(target) =>
                          updateCurrentSigningDetails(target, "witness2Number")
                        }
                        disabled={!otherSelected}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-3">
        <StyledSecondaryH5>Notary</StyledSecondaryH5>
      </Row>
      <Row>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="notaryName">
            <Form.Label>Notary Name</Form.Label>
            <Form.Control
              name="notaryName"
              type="text"
              value={formData.trust?.notaryName}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "notaryName")
              }
            />
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group className="mb-3" controlId="notaryPhone">
            <Form.Label>Notary Phone</Form.Label>
            <MaskedInput
              name="notaryPhone"
              className="form-control"
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={formData.trust?.notaryPhone}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "notaryPhone")
              }
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <StyledSecondaryH5>Realtor</StyledSecondaryH5>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-3" controlId="realtorName">
            <Form.Label>Realtor Name</Form.Label>
            <Form.Control
              name="realtorName"
              type="text"
              value={formData.trust?.realtorName}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "realtorName")
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="realtorCompany">
            <Form.Label>Realtor Company</Form.Label>
            <Form.Control
              name="realtorCompany"
              type="text"
              value={formData.trust?.realtorCompany}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "realtorCompany")
              }
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3" controlId="realtorPhone">
            <Form.Label>Realtor Phone</Form.Label>
            <MaskedInput
              name="Realtor Phone"
              className="form-control"
              mask={[
                "(",
                /[1-9]/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
              ]}
              value={formData.trust?.realtorPhone}
              onChange={(target) =>
                updateCurrentSigningDetails(target, "realtorPhone")
              }
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}
